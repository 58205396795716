var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal"
  }, [_c('wrapper', {
    scopedSlots: _vm._u([{
      key: "alert",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.message) + " ")];
      },
      proxy: true
    }, {
      key: "title",
      fn: function () {
        return [_vm._v(" Самое время создать аккаунт"), _c('br'), _vm._v(" и продолжить работу! ")];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('AuthForm', {
          attrs: {
            "action": "register"
          }
        }), _c('div', {
          staticClass: "disclaimer"
        }, [_vm._v(" Регистрируясь, вы соглашаетесь с "), _c('router-link', {
          attrs: {
            "to": "/privacy",
            "target": "_blank",
            "active-class": ""
          }
        }, [_vm._v(" политикой обработки ")]), _c('router-link', {
          attrs: {
            "to": "/privacy",
            "target": "_blank",
            "active-class": ""
          }
        }, [_vm._v(" персональных данных ")]), _vm._v(" и "), _c('router-link', {
          attrs: {
            "to": "/agreement",
            "active-class": ""
          }
        }, [_vm._v(" договором оферты ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };